@if (bestInvestFeaturesAvailable) {
  <div
    fxFlex="100%"
    fxLayout="row wrap"
    fxLayoutAlign="flex-start center"
    class="container"
    [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
  >
    <h1>
      {{ 'benefits.best_invest.best_invest_savings' | translate }}
    </h1>
    <mat-slider
      min="1"
      max="20"
      step="1"
      [color]="'accent'"
      discrete
      [displayWith]="formatLabel.bind(this)"
      data-test="best-invest-slider"
      gaClickListener="BestInvestSlider"
    >
      <input matSliderThumb value="{{ sliderValue }}" />
    </mat-slider>
    <div
      fxFlex="100%"
      fxLayout="row grid"
      [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
    >
      <div
        *ngIf="monetaryBenefitsData && monetaryBenefitsData.length > 0"
        fxFlex="100%"
        [fxFlex.gt-sm]="'50%'"
      >
        <sl-shared-reporting-single-value-widget
          [value]="totalOfMonetaryBenefits"
          [text]="'benefits.best_invest.total_financial_savings' | translate"
          [color]="singleValueColors[0]"
          [isClickable]="false"
        ></sl-shared-reporting-single-value-widget>
      </div>
      <div
        *ngIf="softFactsData && softFactsData.length > 0"
        fxFlex="100%"
        [fxFlex.gt-sm]="'50%'"
      >
        <sl-shared-reporting-single-value-widget
          [value]="numberOfSoftFacts"
          [text]="'benefits.best_invest.further_benefits' | translate"
          [color]="singleValueColors[1]"
          [isClickable]="false"
        ></sl-shared-reporting-single-value-widget>
      </div>
    </div>
    <div
      fxFlex="100%"
      fxLayout="row grid"
      [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
    >
      <div
        *ngIf="monetaryBenefitsData && monetaryBenefitsData.length > 0"
        fxFlex="100%"
        [fxFlex.gt-sm]="'50%'"
      >
        <sl-shared-reporting-bar-chart
          barsColorScheme="success"
          [headline]="'benefits.best_invest.monetary_benefits' | translate"
          [data]="monetaryBenefitsChartData"
          [currencyCode]="currencyCode"
          (barClick)="openMoreDetailsDialog('' + $event)"
        ></sl-shared-reporting-bar-chart>
      </div>
      <div
        *ngIf="softFactsData && softFactsData.length > 0"
        fxFlex="100%"
        [fxFlex.gt-sm]="'50%'"
      >
        <sl-upselling-features-table
          [features]="softFactsData"
          [currencyCode]="currencyCode"
        >
        </sl-upselling-features-table>
      </div>
    </div>
  </div>
} @else if (noBestInvestFeaturesForModelAvailable) {
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="full-height full-width"
  >
    <ds-placeholder [type]="'no_data'">
      {{ 'benefits.best_invest.no_model_features_available' | translate }}
    </ds-placeholder>
  </div>
} @else {
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="full-height full-width"
  >
    <ds-placeholder [type]="'no_data'">
      {{ 'benefits.best_invest.no_features_available' | translate }}
    </ds-placeholder>
  </div>
}
