import { createAction, props } from '@ngrx/store';
import {
  BestInvestFeatures,
  BestInvestFeaturesInput,
  ProblemDetails,
  Utilization,
  UtilizationInput,
  UtilizationSettings,
  UtilizationSettingsInput,
} from '@sales-libs/upselling/data-access';

export namespace SlUpsellingActions {
  export const getUtilizationForCart = createAction(
    '[Upselling] GET_UTILIZATION_FOR_CART',
    props<{ cartId: number }>(),
  );
  export const getUtilizationForCartSuccess = createAction(
    '[Upselling] GET_UTILIZATION_FOR_CART_SUCCESS',
    props<{ utilization: Utilization }>(),
  );
  export const getUtilizationForCartError = createAction(
    '[Upselling] GET_UTILIZATION_FOR_CART_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const getUtilizationSettings = createAction(
    '[Upselling] GET_UTILIZATION_SETTINGS',
  );
  export const getUtilizationSettingsSuccess = createAction(
    '[Upselling] GET_UTILIZATION_SETTINGS_SUCCESS',
    props<{ utilizationSettings: UtilizationSettings }>(),
  );
  export const getUtilizationSettingsError = createAction(
    '[Upselling] GET_UTILIZATION_SETTINGS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const updateUtilizationSettings = createAction(
    '[Upselling] UPDATE_UTILIZATION_SETTINGS',
    props<{
      utilizationSettingsInput: UtilizationSettingsInput;
    }>(),
  );
  export const updateUtilizationSettingsSuccess = createAction(
    '[Upselling] UPDATE_UTILIZATION_SETTINGS_SUCCESS',
    props<{ utilizationSettings: UtilizationSettings }>(),
  );
  export const updateUtilizationSettingsError = createAction(
    '[Upselling] UPDATE_UTILIZATION_SETTINGS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const createDefaultUtilizationForCart = createAction(
    '[Upselling] CREATE_DEFAULT_UTILIZATION_FOR_CART',
    props<{
      cartId: number;
    }>(),
  );
  export const createDefaultUtilizationForCartSuccess = createAction(
    '[Upselling] CREATE_DEFAULT_UTILIZATION_FOR_CART_SUCCESS',
    props<{ utilization: Utilization }>(),
  );
  export const createDefaultUtilizationForCartError = createAction(
    '[Upselling] CREATE_DEFAULT_UTILIZATION_FOR_CART_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const createDefaultUtilizationSettings = createAction(
    '[Upselling] CREATE_DEFAULT_UTILIZATION_SETTINGS',
  );
  export const createDefaultUtilizationSettingsSuccess = createAction(
    '[Upselling] CREATE_DEFAULT_UTILIZATION_SETTINGS_SUCCESS',
    props<{ utilizationSettings: UtilizationSettings }>(),
  );
  export const createDefaultUtilizationSettingsError = createAction(
    '[Upselling] CREATE_DEFAULT_UTILIZATION_SETTINGS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const updateUtilizationForCart = createAction(
    '[Upselling] UPDATE_UTILIZATION_FOR_CART',
    props<{ cartId: number; utilizationInput: UtilizationInput }>(),
  );
  export const updateUtilizationForCartSuccess = createAction(
    '[Upselling] UPDATE_UTILIZATION_FOR_CART_SUCCESS',
    props<{ utilization: Utilization }>(),
  );
  export const updateUtilizationForCartError = createAction(
    '[Upselling] UPDATE_UTILIZATION_FOR_CART_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const setUtilizationToDefaults = createAction(
    '[Upselling] SET_UTILIZATION_TO_DEFAULTS',
    props<{ cartId: number }>(),
  );
  export const setUtilizationToDefaultsSuccess = createAction(
    '[Upselling] SET_UTILIZATION_TO_DEFAULTS_SUCCESS',
    props<{ utilization: Utilization }>(),
  );
  export const setUtilizationToDefaultsError = createAction(
    '[Upselling] SET_UTILIZATION_TO_DEFAULTS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const setDefaultUtilizationSettings = createAction(
    '[Upselling] SET_DEFAULT_UTILIZATION_SETTINGS',
  );
  export const setDefaultUtilizationSettingsSuccess = createAction(
    '[Upselling] SET_DEFAULT_UTILIZATION_SETTINGS_SUCCESS',
    props<{ utilizationSettings: UtilizationSettings }>(),
  );
  export const setDefaultUtilizationSettingsError = createAction(
    '[Upselling] SET_DEFAULT_UTILIZATION_SETTINGS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  // BEST INVEST - FEATURES
  export const getBestInvestFeatures = createAction(
    '[Cart] GET_BEST_INVEST_FEATURES',
    props<{
      projectId: number;
      cartId: number;
      bestInvestFeaturesInput: BestInvestFeaturesInput;
    }>(),
  );
  export const getBestInvestFeaturesSuccess = createAction(
    '[Cart] GET_BEST_INVEST_FEATURES_SUCCESS',
    props<{ payload: BestInvestFeatures }>(),
  );
  export const getBestInvestFeaturesError = createAction(
    '[Cart] GET_BEST_INVEST_FEATURES_ERROR',
    props<{ payload: ProblemDetails }>(),
  );
}
