import { CurrencyPipe } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { dsConfig } from '@design-system/cdk/config';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsTableModule } from '@design-system/components/table';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedContractActions } from '@sales-libs/shared/feature';
import {
  ChartData,
  SlSharedReportingBarChartComponent,
  SlSharedReportingSingleValueWidgetComponent,
} from '@sales-libs/shared/ui';
import {
  BestInvestFeature,
  BestInvestFeaturesInput,
} from '@sales-libs/upselling/data-access';
import { SlDetailsDialogComponent } from '@sales-libs/upselling/ui';
import { GoogleAnalytics4Service } from '@shared-lib/google-analytics';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { of, switchMap, take, tap } from 'rxjs';
import { SlUpsellingBestInvestFeaturesTableComponent } from '../best-invest-features-table/best-invest-features-table.component';
import { SlUpsellingActions } from '../store';

@Component({
  selector: 'sl-upselling-best-invest-savings',
  templateUrl: './best-invest-savings.component.html',
  styleUrls: ['./best-invest-savings.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexLayoutModule,
    MatRadioModule,
    MatIconModule,
    MatSliderModule,
    NgxChartsModule,
    DsSpacingModule,
    DsLoadingModule,
    SlSharedReportingBarChartComponent,
    SlSharedReportingSingleValueWidgetComponent,
    SlUpsellingBestInvestFeaturesTableComponent,
    DsTableModule,
    DsPlaceholderModule,
  ],
  providers: [CurrencyPipe],
})
export class SlUpsellingBestInvestSavingsComponent implements OnInit {
  @Input()
  projectId: number;
  @Input()
  cartId: number;
  @Input()
  currencyCode: string;
  @Input()
  featuresInput: BestInvestFeaturesInput;

  bestInvestFeaturesAvailable: boolean;
  noBestInvestFeaturesForModelAvailable: boolean;
  softFactsData: BestInvestFeature[];
  monetaryBenefitsData: BestInvestFeature[];
  monetaryBenefitsChartData: ChartData[];

  totalOfMonetaryBenefits: string;
  numberOfSoftFacts: number;

  sliderValue: number;
  singleValueColors: string[] = [
    dsConfig.colors.success[600],
    dsConfig.colors.success[400],
  ];

  private readonly _sliderYearsLocalStorageKey = 'selected_slider_years';

  constructor(
    public readonly _store: Store,
    public readonly _currencyPipe: CurrencyPipe,
    public readonly actions: Actions,
    public readonly dialog: MatDialog,
    private readonly _translateService: TranslateService,
    readonly _ga4Service: GoogleAnalytics4Service,
  ) {
    this.sliderValue = parseInt(
      localStorage.getItem(this._sliderYearsLocalStorageKey) ?? '5',
      10,
    );
  }

  ngOnInit() {
    this.getBestInvestFeatures();
  }

  formatLabel(value: number): string {
    this.sliderValue = value;
    this._mapChartData();
    this._setReportingData();
    this._saveSliderYearsToLocalStorage();

    if (value === 1) {
      return `${value} ${this._translateService.instant('benefits.year')}`;
    }
    return this._translateService.instant('benefits.years', {
      years: value,
    });
  }

  getBestInvestFeatures(): void {
    if (
      !this.projectId ||
      !this.cartId ||
      !this.featuresInput.feature_filtering?.product
    ) {
      this.bestInvestFeaturesAvailable = false;
      this.noBestInvestFeaturesForModelAvailable = false;
      return;
    }

    this._store.dispatch(
      SlUpsellingActions.getBestInvestFeatures({
        projectId: this.projectId,
        cartId: this.cartId,
        bestInvestFeaturesInput: this.featuresInput,
      }),
    );

    this.actions
      .pipe(
        ofType(
          SlUpsellingActions.getBestInvestFeaturesSuccess,
          SlUpsellingActions.getBestInvestFeaturesError,
        ),
        take(1),
        switchMap((action) => {
          if (
            action.type === SlUpsellingActions.getBestInvestFeaturesSuccess.type
          ) {
            return of(this._handleFeaturesSuccess(action));
          } else if (action.payload.status === HttpStatusCode.NotFound) {
            return this._handleNotFoundError();
          } else {
            this.bestInvestFeaturesAvailable = false;
            this.noBestInvestFeaturesForModelAvailable = false;
            return of(false);
          }
        }),
      )
      .subscribe();
  }
  openMoreDetailsDialog(dataId: string): void {
    const feature = this.monetaryBenefitsData?.find(
      (data) => data.id === dataId,
    );

    if (feature && (feature.description || feature.assumptions)) {
      this._ga4Service.event('OpenMoreDetailsDialogBarChartClick', {
        category: 'BestInvestSavings',
        label: 'BestInvest',
      });

      this.dialog.open(SlDetailsDialogComponent, {
        width: String(dsConfig.spacing * 25) + 'px',
        data: {
          feature: feature,
        },
      });
    }
  }

  private _handleNotFoundError() {
    this._store.dispatch(
      SharedContractActions.createDefaultUtilizationForCart({
        cartId: this.cartId,
      }),
    );

    return this.actions.pipe(
      ofType(SharedContractActions.createDefaultUtilizationForCartSuccess),
      take(1),
      tap(() => {
        this._store.dispatch(
          SlUpsellingActions.getBestInvestFeatures({
            projectId: this.projectId,
            cartId: this.cartId,
            bestInvestFeaturesInput: this.featuresInput,
          }),
        );
      }),
      switchMap(() =>
        this.actions.pipe(
          ofType(SlUpsellingActions.getBestInvestFeaturesSuccess),
          take(1),
          tap((data) => this._handleFeaturesSuccess(data)),
        ),
      ),
    );
  }

  private _handleFeaturesSuccess(data: any): void {
    if (
      (!data.payload.monetary_benefits ||
        data.payload.monetary_benefits.length === 0) &&
      (!data.payload.soft_facts || data.payload.soft_facts.length === 0)
    ) {
      this.noBestInvestFeaturesForModelAvailable = true;
      return;
    }

    this.monetaryBenefitsData = data.payload.monetary_benefits ?? [];
    this.softFactsData = data.payload.soft_facts ?? [];
    this._mapChartData();
    this._setReportingData();

    this.bestInvestFeaturesAvailable = true;
    this.noBestInvestFeaturesForModelAvailable = false;
  }

  private _setReportingData() {
    const sumOfMonetaryBenefits = this.monetaryBenefitsData
      ?.map((item) => item.value ?? 0)
      .reduce((prev, curr) => prev + curr, 0)
      .toFixed(2);
    const sumWithSliderValue = +sumOfMonetaryBenefits * this.sliderValue;

    this.totalOfMonetaryBenefits =
      this._currencyPipe.transform(
        sumWithSliderValue,
        this.currencyCode,
        'symbol',
      ) ?? '';

    this.numberOfSoftFacts = this.softFactsData?.length;
  }

  private _mapChartData() {
    this.monetaryBenefitsChartData = this._mapItems(
      this.monetaryBenefitsData ?? [],
    );
  }

  private _mapItems(items: BestInvestFeature[]): ChartData[] {
    const initialAcc: ChartData[] = [];

    items.forEach((item) =>
      initialAcc.push({
        id: item.id,
        name: item.name ?? '',
        value: (item.value ?? 0) * this.sliderValue,
      }),
    );

    return initialAcc;
  }

  private _saveSliderYearsToLocalStorage() {
    localStorage.setItem(
      this._sliderYearsLocalStorageKey,
      this.sliderValue.toString(),
    );
  }
}
