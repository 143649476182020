import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UserService } from '@features/auth';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { UNITS } from '@sales-libs/shared/util';

import { HttpStatusCode } from '@angular/common/http';
import { Currency } from '@sales-libs/settings/data-access';
import { SlSettingsCurrencyDependendPageComponent } from '@sales-libs/settings/feature';
import { SharedContractActions } from '@sales-libs/shared/feature';
import {
  GlobalUtilizationParameters,
  Utilization,
  UtilizationSettings,
} from '@sales-libs/upselling/data-access';
import { take } from 'rxjs/operators';
import { SlUpsellingActions, SlUpsellingSelectors } from '../store';

@Component({
  selector: 'sl-upselling-best-invest-extended-input-data',
  templateUrl: './best-invest-extended-input-data.component.html',
  styleUrls: ['./best-invest-extended-input-data.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexLayoutModule,
    MatIconModule,
    SlSettingsCurrencyDependendPageComponent,
  ],
})
export class SlUpsellingBestInvestExtendedInputDataComponent
  implements OnInit, OnChanges
{
  @Input()
  cartId: number;
  @Input() cancelSave: boolean;
  @Input() isReadOnly: boolean;
  extendedBestInvestForm: FormGroup;
  utilization: Utilization | undefined;
  utilizationSettings: UtilizationSettings;
  Units = UNITS;
  @Output() resetToUtilizationSettings = new EventEmitter<{
    externalWorkingHourCosts: number;
  }>();
  @Output() extendedFormStatusChange = new EventEmitter<{
    formData: Utilization;
    formUpdated: boolean;
  }>();
  isFormUpdated = false;
  currency: Currency;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    public actions: Actions,
    private readonly destroyRef: DestroyRef,
    private readonly _userService: UserService,
  ) {
    this.extendedBestInvestForm = this.formBuilder.group({
      price_diesel: [{ value: '', disabled: true }],
      price_hydraulic_oil: [{ value: '', disabled: true }],
      price_lubricant: [{ value: '', disabled: true }],
      price_co2_per_ton: [{ value: '', disabled: true }],
    });

    this.extendedBestInvestForm.statusChanges.subscribe(() => {
      this.isFormUpdated = this._hasFormChanged();

      const formValues = this.extendedBestInvestForm.getRawValue();
      this.extendedFormStatusChange.emit({
        formData: formValues,
        formUpdated: this.isFormUpdated,
      });
    });
  }

  ngOnInit(): void {
    this.fetchUpdatedParameters();
    this.getUtilizationParametersFromStore();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cancelSave'] && this.cancelSave) {
      this.fetchUpdatedParameters();
    }
  }

  loadCurrency(currency: Currency): void {
    this.currency = currency;
  }

  getUtilizationParametersFromStore() {
    this.store
      .select(SlUpsellingSelectors.utilization)
      .pipe(take(1))
      .subscribe((utilization) => {
        this.utilization = utilization;
      });

    this.actions
      .pipe(
        ofType(SharedContractActions.createDefaultUtilizationForCartSuccess),
        take(1),
      )
      .subscribe((payload) => {
        this.utilization = payload.utilization;
      });

    if (this.utilization?.global_parameters)
      this._setUtilizationSettingsValues(this.utilization.global_parameters);
  }

  resetToGlobalValues(): void {
    this.store.dispatch(
      SlUpsellingActions.setUtilizationToDefaults({
        cartId: this.cartId,
      }),
    );

    this.actions
      .pipe(ofType(SlUpsellingActions.setUtilizationToDefaultsSuccess), take(1))
      .subscribe((event) => {
        const utilization = event.utilization;
        if (utilization.customer_parameters && utilization.global_parameters)
          this.utilizationSettings = {
            id: utilization.id,
            company_bpm_id: this._userService.userContext.bpm_company_id,
            costs_external_working_hour:
              utilization.customer_parameters?.costs_external_working_hour,
            price_diesel: utilization.global_parameters.price_diesel,
            price_hydraulic_oil:
              utilization.global_parameters.price_hydraulic_oil,
            price_lubricant: utilization.global_parameters.price_lubricant,
            price_co2_per_ton: utilization.global_parameters.price_co2_per_ton,
          };
        this._setUtilizationSettingsValues(this.utilizationSettings);
        this.resetToUtilizationSettings.emit({
          externalWorkingHourCosts:
            this.utilizationSettings.costs_external_working_hour,
        });
      });

    this.actions
      .pipe(ofType(SlUpsellingActions.setUtilizationToDefaultsError), take(1))
      .subscribe((error) => {
        if (error.payload.status === HttpStatusCode.NotFound) {
          this.getUtilizationParametersFromStore();
        }
      });
  }

  fetchUpdatedParameters(): void {
    this.actions
      .pipe(
        ofType(SlUpsellingActions.updateUtilizationSettingsSuccess),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((state) => {
        this._setUtilizationSettingsValues(state.utilizationSettings);
      });
  }

  private _hasFormChanged(): boolean {
    const initialParams = this.utilization?.global_parameters ?? {};

    return Object.keys(this.extendedBestInvestForm.controls).some(
      (key) =>
        this.extendedBestInvestForm.get(key)?.value !== initialParams[key],
    );
  }

  private _setUtilizationSettingsValues(
    parameters: GlobalUtilizationParameters,
  ): void {
    this.extendedBestInvestForm.patchValue({
      price_diesel: parameters.price_diesel,
      price_hydraulic_oil: parameters.price_hydraulic_oil,
      price_lubricant: parameters.price_lubricant,
      price_co2_per_ton: parameters.price_co2_per_ton,
    });
  }
}
