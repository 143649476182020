import { Utilization, UtilizationInput } from '../generated';

export const testUtilizationInput: UtilizationInput = {
  customer_parameters: {
    utilization_km_per_year: 1,
    costs_external_working_hour: 70,
    costs_internal_working_hour: 1,
    share_high_activity: 60,
    share_low_activity: 35,
    share_setting_up: 5,
    working_days_per_year: 1,
    working_hours_per_day: 1,
    working_hours_per_year: 1,
  },
  global_parameters: {
    price_co2_per_ton: 1,
    price_diesel: 1.4,
    price_hydraulic_oil: 1,
    price_lubricant: 1,
  },
};

export const testUtilizationParameters: Utilization = {
  id: 1,
  cart_id: 1234,
  ...testUtilizationInput,
};
