import { Action, createReducer, on } from '@ngrx/store';
import { SlUpsellingActions } from './upselling.actions';
import { SlUpsellingState, initialState } from './upselling.store';

export const slUpsellingReducer = createReducer(
  initialState,

  on(SlUpsellingActions.getUtilizationForCart, (state) => ({
    ...state,
  })),
  on(
    SlUpsellingActions.getUtilizationForCartSuccess,
    (state, { utilization }) => ({
      ...state,
      utilization,
    }),
  ),
  on(SlUpsellingActions.getUtilizationForCartError, (state, { payload }) => ({
    ...state,
    gettingUtilizationForCartError: payload,
  })),

  on(SlUpsellingActions.updateUtilizationSettings, (state) => ({
    ...state,
  })),
  on(
    SlUpsellingActions.updateUtilizationSettingsSuccess,
    (state, { utilizationSettings }) => ({
      ...state,
      utilizationSettings,
    }),
  ),
  on(
    SlUpsellingActions.updateUtilizationSettingsError,
    (state, { payload }) => ({
      ...state,
      updateUtilizationSettingsError: payload,
    }),
  ),

  on(SlUpsellingActions.getBestInvestFeatures, (state) => ({
    ...state,
  })),
  on(SlUpsellingActions.getBestInvestFeaturesSuccess, (state, { payload }) => ({
    ...state,
    payload: payload,
  })),
  on(SlUpsellingActions.getBestInvestFeaturesError, (state, { payload }) => ({
    ...state,
    gettingBestInvestFeaturesError: payload,
  })),

  on(SlUpsellingActions.createDefaultUtilizationSettings, (state) => ({
    ...state,
  })),
  on(
    SlUpsellingActions.createDefaultUtilizationSettingsSuccess,
    (state, { utilizationSettings }) => ({
      ...state,
      utilizationSettings,
    }),
  ),
  on(
    SlUpsellingActions.createDefaultUtilizationSettingsError,
    (state, { payload }) => ({
      ...state,
      createDefaultUtilizationSettingsError: payload,
    }),
  ),

  on(SlUpsellingActions.setUtilizationToDefaults, (state) => ({
    ...state,
  })),
  on(
    SlUpsellingActions.setUtilizationToDefaultsSuccess,
    (state, { utilization }) => ({
      ...state,
      utilization,
    }),
  ),
  on(
    SlUpsellingActions.setUtilizationToDefaultsError,
    (state, { payload }) => ({
      ...state,
      setUtilizationToDefaultsError: payload,
    }),
  ),
);

export function reducer(state: SlUpsellingState | undefined, action: Action) {
  return slUpsellingReducer(state, action);
}
