import { HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { SharedContractActions } from '@sales-libs/shared/feature';
import {
  BestInvestFeatures,
  BestInvestService,
  Utilization,
  UtilizationSettings,
} from '@sales-libs/upselling/data-access';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SlUpsellingActions } from './upselling.actions';

@Injectable()
export class SlUpsellingEffects {
  private readonly actions$ = inject(Actions);

  createDefaultUtilizationForCart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedContractActions.createDefaultUtilizationForCart),
      switchMap((payload) =>
        this.bestInvestService
          .createDefaultUtilizationForCart(payload.cartId)
          .pipe(
            map((utilization: Utilization) =>
              SharedContractActions.createDefaultUtilizationForCartSuccess({
                utilization,
              }),
            ),
            catchError((err) =>
              of(
                SharedContractActions.createDefaultUtilizationForCartError({
                  payload: err,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getUtilizationForCart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.getUtilizationForCart),
      switchMap((payload) =>
        this.bestInvestService.getUtilizationForCart(payload.cartId).pipe(
          map((utilization: Utilization) =>
            SlUpsellingActions.getUtilizationForCartSuccess({
              utilization,
            }),
          ),
          catchError((err) =>
            of(
              SlUpsellingActions.getUtilizationForCartError({
                payload: err,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getUtilizationSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.getUtilizationSettings),
      switchMap(() =>
        this.bestInvestService.getUtilizationSettings().pipe(
          map((utilizationSettings: UtilizationSettings) =>
            SlUpsellingActions.getUtilizationSettingsSuccess({
              utilizationSettings,
            }),
          ),
          catchError((err) => {
            if (err.status === HttpStatusCode.NotFound) {
              return of(SlUpsellingActions.createDefaultUtilizationSettings());
            }
            return of(
              SlUpsellingActions.getUtilizationSettingsError({
                payload: err,
              }),
            );
          }),
        ),
      ),
    ),
  );

  updateUtilizationSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.updateUtilizationSettings),
      switchMap((payload) =>
        this.bestInvestService
          .updateUtilizationSettings(payload.utilizationSettingsInput)
          .pipe(
            map((utilizationSettings: UtilizationSettings) =>
              SlUpsellingActions.updateUtilizationSettingsSuccess({
                utilizationSettings,
              }),
            ),
            catchError((err) =>
              of(
                SlUpsellingActions.updateUtilizationSettingsError({
                  payload: err,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getBestInvestFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.getBestInvestFeatures),
      switchMap((payload) =>
        this.bestInvestService
          .getBestInvestFeatures(
            payload.projectId,
            payload.cartId,
            payload.bestInvestFeaturesInput,
          )
          .pipe(
            map((data: BestInvestFeatures) =>
              SlUpsellingActions.getBestInvestFeaturesSuccess({
                payload: data,
              }),
            ),
            catchError((err) =>
              of(
                SlUpsellingActions.getBestInvestFeaturesError({
                  payload: err,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  createDefaultUtilizationSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.createDefaultUtilizationSettings),
      switchMap(() =>
        this.bestInvestService.createDefaultUtilizationSettings().pipe(
          map((utilizationSettings: UtilizationSettings) =>
            SlUpsellingActions.createDefaultUtilizationSettingsSuccess({
              utilizationSettings,
            }),
          ),
          catchError((err) =>
            of(
              SlUpsellingActions.createDefaultUtilizationSettingsError({
                payload: err,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updateUtilizationForCart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.updateUtilizationForCart),
      switchMap((payload) =>
        this.bestInvestService
          .updateUtilizationForCart(payload.cartId, payload.utilizationInput)
          .pipe(
            map((utilization: Utilization) =>
              SlUpsellingActions.updateUtilizationForCartSuccess({
                utilization,
              }),
            ),
            catchError((err) =>
              of(
                SlUpsellingActions.updateUtilizationForCartError({
                  payload: err,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  setUtilizationToDefaults$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.setUtilizationToDefaults),
      switchMap((payload) =>
        this.bestInvestService.setUtilizationToDefaults(payload.cartId).pipe(
          map((utilization: Utilization) =>
            SlUpsellingActions.setUtilizationToDefaultsSuccess({
              utilization,
            }),
          ),
          catchError((err) =>
            of(
              SlUpsellingActions.setUtilizationToDefaultsError({
                payload: err,
              }),
            ),
          ),
        ),
      ),
    ),
  );
  setDefaultUtilizationSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlUpsellingActions.setDefaultUtilizationSettings),
      switchMap(() =>
        this.bestInvestService.setDefaultUtilizationSettings().pipe(
          map((utilizationSettings: UtilizationSettings) =>
            SlUpsellingActions.setDefaultUtilizationSettingsSuccess({
              utilizationSettings,
            }),
          ),
          catchError((err) =>
            of(
              SlUpsellingActions.setDefaultUtilizationSettingsError({
                payload: err,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    @Inject(BestInvestService)
    private readonly bestInvestService: BestInvestService,
  ) {}
}
