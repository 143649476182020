import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalUtilizationParameters } from '@sales-libs/upselling/data-access';
import { SlUpsellingBestInvestExtendedInputDataComponent } from '../best-invest-extended-input-data/best-invest-extended-input-data.component';
import { SlUpsellingBestInvestInputDataComponent } from '../best-invest-input-data/best-invest-input-data.component';
import { SlUpsellingActions } from '../store';

@Component({
  selector: 'sl-upselling-best-invest-dialog',
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    MatSlideToggleModule,
    FormsModule,
    SlUpsellingBestInvestExtendedInputDataComponent,
    SlUpsellingBestInvestInputDataComponent,
  ],
  templateUrl: './utilization-dialog.component.html',
  styleUrls: ['./utilization-dialog.component.scss'],
})
export class SlUpsellingUtilizationDialogComponent implements OnInit {
  showExtendedInputData = false;
  isValid = false;
  isUpdated = false;
  isExtendedFormUpdated = false;
  formData;
  extendedFormData;
  @ViewChild('extendedDataInput') extendedDataInput!: ElementRef;
  updatedExternalWorkingHourCosts: number;
  revertParametersClicked = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { cartId: number; isReadOnly: boolean },
    private readonly dialogRef: MatDialogRef<SlUpsellingUtilizationDialogComponent>,
    private readonly store: Store,
    private readonly actions: Actions,
    private readonly destroyRef: DestroyRef,
    private readonly snackbar: DsSnackbarService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.dialogRef.beforeClosed().subscribe(() => {
      this.revertParameters();
    });
  }

  onFormStatusChange($event) {
    this.isValid = $event.isValid;
    this.formData = $event.formData;
    this.isUpdated = $event.formUpdated;
  }
  onExtendedFormStatusChange($event) {
    this.extendedFormData = $event.formData;
    this.isExtendedFormUpdated = $event.formUpdated;
  }

  scrollToForm() {
    if (this.extendedDataInput && this.showExtendedInputData) {
      this.extendedDataInput.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  updateParameters() {
    if (this.isValid) {
      const cartId = this.data.cartId;
      let mappedExtendedData: GlobalUtilizationParameters | undefined;

      if (this.extendedFormData !== undefined) {
        mappedExtendedData = {
          price_diesel: this.extendedFormData.price_diesel,
          price_hydraulic_oil: this.extendedFormData.price_hydraulic_oil,
          price_lubricant: this.extendedFormData.price_lubricant,
          price_co2_per_ton: this.extendedFormData.price_co2_per_ton,
        };
      } else {
        mappedExtendedData = undefined;
      }

      const mappedData = {
        customer_parameters: {
          utilization_km_per_year: this.formData.utilization_km_per_year,
          working_hours_per_day: this.formData.working_hours_per_day,
          working_days_per_year: this.formData.working_days_per_year,
          working_hours_per_year: this.formData.working_hours_per_year,
          share_low_activity: this.formData.share_low_activity,
          share_high_activity: this.formData.share_high_activity,
          share_setting_up: this.formData.share_setting_up,
          costs_internal_working_hour:
            this.formData.costs_internal_working_hour,
          costs_external_working_hour:
            this.formData.costs_external_working_hour,
        },
        global_parameters: mappedExtendedData,
      };

      this.store.dispatch(
        SlUpsellingActions.updateUtilizationForCart({
          cartId: cartId,
          utilizationInput: mappedData,
        }),
      );
      this.actions
        .pipe(
          ofType(SlUpsellingActions.updateUtilizationForCartSuccess),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          this.snackbar.queue(
            this.translateService.instant('best_invest.parameters_updated'),
            {
              type: DsSnackbarType.Success,
            },
          );
          this.dialogRef.close();
        });

      this.actions
        .pipe(
          ofType(SlUpsellingActions.updateUtilizationForCartError),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          this.snackbar.queue(
            this.translateService.instant(
              'error_messages.best_invest.parameters_not_updated',
            ),
            {
              type: DsSnackbarType.Error,
            },
          );
          this.dialogRef.close();
        });
    }
  }

  revertParameters(): void {
    this.revertParametersClicked = true;
  }

  resetValueToUtilizationSettings({ externalWorkingHourCosts }): void {
    this.updatedExternalWorkingHourCosts = externalWorkingHourCosts;
  }
}
