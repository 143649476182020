import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SlUpsellingState } from './upselling.store';

export namespace SlUpsellingSelectors {
  export const slUpsellingState =
    createFeatureSelector<SlUpsellingState>('SlUpsellingState');

  export const utilization = createSelector(
    slUpsellingState,
    (state) => state?.utilization,
  );

  export const bestInvestFeatures = createSelector(
    slUpsellingState,
    (state) => state?.bestInvestFeatures,
  );

  export const utilizationSettings = createSelector(
    slUpsellingState,
    (state) => state?.utilizationSettings,
  );
}
