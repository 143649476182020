import {
  BestInvestFeatures,
  Utilization,
  UtilizationSettings,
} from '@sales-libs/upselling/data-access';

export interface SlUpsellingState {
  bestInvestFeatures?: BestInvestFeatures;
  utilization?: Utilization;
  utilizationSettings?: UtilizationSettings;
}

export const initialState: SlUpsellingState = {
  bestInvestFeatures: undefined,
  utilization: undefined,
  utilizationSettings: undefined,
};
